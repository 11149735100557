// src/ResultsPage.js

import React, { useState, useEffect, useRef } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { fetchAuthSession } from '@aws-amplify/auth';
import { useParams } from 'react-router-dom';
import './ResultsPage.css';
import { GetObjectCommand } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { createS3Client } from './chunkHelper'; // Import createS3Client from chunkHelper.js

Amplify.configure(awsconfig);

function ResultsPage() {
  const { batchId } = useParams();
  const [results, setResults] = useState(null);
  const [message, setMessage] = useState('');
  
  // **New State for idToken**
  const [idToken, setIdToken] = useState(null);

  // Ref to keep track of polling attempts
  const attemptsRef = useRef(0);
  const maxAttempts = 3;

  useEffect(() => {
    let isMounted = true; // To prevent state updates if component is unmounted

    const pollResults = async () => {
      if (attemptsRef.current >= maxAttempts) {
        if (isMounted && !results) {
          setMessage('Batch is still processing. Please check back later.');
        }
        return;
      }

      await fetchResults();

      if (results) {
        // If results are fetched, stop polling
        return;
      }

      attemptsRef.current += 1;
      setTimeout(pollResults, 1000); // Poll again after 1 second
    };

    pollResults();

    return () => {
      isMounted = false; // Cleanup flag on unmount
    };
  }, [batchId, results]);

  const fetchResults = async () => {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.idToken.toString();
      
      // **Set idToken in State**
      setIdToken(token);

      // **ADDED LOGGING START**
      // Decode the ID token to access its payload
      const payload = JSON.parse(atob(token.split('.')[1]));
      console.log('User Authentication Session:', {
        isValid: session.isValid,
        user: payload['cognito:username'] || payload.sub, // Adjust based on actual claim names
        tokens: {
          accessToken: session.tokens.accessToken.jwtToken, // Ensure correct path
          idToken: payload.sub,
        },
      });
      // **ADDED LOGGING END**

      const response = await fetch(`https://f3dtih3bqe.execute-api.us-east-1.amazonaws.com/dev/result?batchId=${batchId}`, {
        headers: {
          Authorization: token, // **Modified Line**
        },
      });

      console.log(`Response Status: ${response.status}`); // Log response status

      if (!response.ok) {
        const errorText = await response.text(); // Get response text for more detailed error
        console.log(`Response Error: ${errorText}`);
        throw new Error(`HTTP error! status: ${response.status}, body: ${errorText}`);
      }

      const data = await response.json();
      console.log('API response:', data); // Log the response
        setResults(data);
    } catch (error) {
      // **ADDED LOGGING START**
      console.error('Error fetching results for batchId:', batchId, error);
      // **ADDED LOGGING END**
      setMessage('Error fetching results');
    }
  };

  // Function to extract key from URL
  const extractKeyFromUrl = (url) => {
    try {
      const urlObj = new URL(url);
      // The object key is the pathname without the leading '/'
      return decodeURIComponent(urlObj.pathname.substring(1));
    } catch (e) {
      console.error('Invalid URL:', url);
      return null;
    }
  };

  // Step 2: Modify downloadFile Function to Use the Authenticated S3 Client
  const downloadFile = async (url) => { // Changed parameter from 'key' to 'url'
    console.log('Downloading from URL:', url); // Updated log
    const bucket = 'my-data-chunks-bucket';

    // Extract key from URL
    const key = extractKeyFromUrl(url);
    if (!key) {
      console.error('Failed to extract key from URL');
      return;
    }

    // **ADDED LOGGING START**
    console.log('Extracted S3 Key:', key);
    console.log('Preparing to send GetObjectCommand with:', {
      Bucket: bucket,
      Key: key,
    });
    // **ADDED LOGGING END**

    try {
      if (!idToken) { // **Check if idToken is available**
        throw new Error('ID Token is not available');
      }

      const s3Client = createS3Client(idToken); // **Pass the idToken here (Removed await)**

      const command = new GetObjectCommand({ Bucket: bucket, Key: key });

      // **ADDED LOGGING START**
      console.log('Sending GetObjectCommand with Bucket:', bucket, 'Key:', key);
      // **ADDED LOGGING END**

      const response = await s3Client.send(command);

      // **ADDED LOGGING START**
      console.log('Received response from S3:', {
        HTTPStatusCode: response.$metadata.httpStatusCode,
        requestId: response.$metadata.requestId,
        extendedRequestId: response.$metadata.extendedRequestId,
        cfId: response.$metadata.cfId,
      });
      // **ADDED LOGGING END**

      // Convert the response body stream into a blob
      const blob = await new Response(response.Body).blob();

      // Create a URL for the blob and initiate a download
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = key.split('/').pop(); // Extract filename from key
      document.body.appendChild(a);
      a.click();
      a.remove();

      // **ADDED LOGGING START**
      console.log(`Download initiated for file: ${a.download}`);
      // **ADDED LOGGING END**
    } catch (error) {
      // **ADDED LOGGING START**
      console.error('Error downloading file with Key:', key, error);
      // **ADDED LOGGING END**
      setMessage(`Error downloading file: ${error.message}`); // **Optional: Inform the user**
    }
  };

  // formatDateTime function
  const formatDateTime = (dateString) => {
    if (!dateString) return { date: 'N/A', time: 'N/A' };
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return { date: 'N/A', time: 'N/A' };
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour: 'numeric', minute: 'numeric' }; // Removed seconds
    return {
      date: date.toLocaleDateString(undefined, dateOptions),
      time: date.toLocaleTimeString(undefined, timeOptions)
    };
  };

  // Render Task Metadata
  const renderTaskMetadata = (metadata) => {
    if (!metadata) return null;

    let parsedMetadata;
    try {
      parsedMetadata = JSON.parse(metadata);
    } catch (error) {
      console.error('Error parsing taskMetadata:', error);
      return null;
    }

    // Extract 'xsName' and 'ysName' from taskMetadata if available
    const xsName = parsedMetadata.xsName || null;
    const ysName = parsedMetadata.ysName || null;

    return (
      <>
        {(xsName || ysName) && (
          <div>
            <strong>Training Data:</strong>
            <ul>
              {xsName && <li>XS: {xsName}</li>}
              {ysName && <li>YS: {ysName}</li>}
            </ul>
          </div>
        )}
      </>
    );
  };

  // Render Model Download Links for TensorFlow Tasks
  const renderModelLinks = (results) => {
    if (!results.taskType) return null;

    if (results.taskType.toLowerCase() !== 'tensorflow') return null;

    let trainedModelLocations = [];

    // **Change Start:** Parse `trainedModelLocations` if it's a string
    if (typeof results.trainedModelLocations === 'string') {
      try {
        trainedModelLocations = JSON.parse(results.trainedModelLocations);
      } catch (error) {
        console.error('Error parsing trainedModelLocations:', error);
        return null;
      }
    } else if (Array.isArray(results.trainedModelLocations)) {
      trainedModelLocations = results.trainedModelLocations;
    }
    // **Change End**

    return (
      <div className="model-links">
        {trainedModelLocations.length > 0 ? (
          trainedModelLocations.map((model, index) => (
            <div key={index} className="trained-model-download">
              <div className="trained-model-header">
                <strong>Trained Models:</strong> <span>Node {index + 1}</span>
              </div>
              <div className="model-file-links">
                {model.modelJson && (
                  <>
                    {console.log('modelJson:', model.modelJson)} {/* Added log */}
                    <a href="#" onClick={(e) => { e.preventDefault(); downloadFile(model.modelJson); }}>
                      model.json
                    </a>
                  </>
                )}
                {model.weights && model.weights.map((weightUrl, weightIndex) => (
                  // **Change Start:** Wrapped in a <div> with key to fix React warning
                  <div key={weightIndex}>
                    {console.log('weights:', weightUrl)} {/* Added log */}
                    <a href="#" onClick={(e) => { e.preventDefault(); downloadFile(weightUrl); }}>
                      weights.bin {weightIndex + 1}
                    </a>
                  </div>
                  // **Change End**
                ))}
              </div>
            </div>
          ))
        ) : (
          <div>No trained models available.</div>
        )}
      </div>
    );
  };

  // Render Other Results, Excluding 'columns' if Present
  const renderResultsContent = (resultsContent) => {
    if (!resultsContent) return null;

    return Object.entries(resultsContent)
      .filter(([key, value]) => key.toLowerCase() !== 'columns' && value !== undefined && value !== null)
      .map(([key, value]) => (
        <div key={key}><strong>{key}:</strong> {Array.isArray(value) ? value.join(', ') : value}</div>
      ));
  };

  return (
    <div className="results-page">
      <div className="results-heading">
        <h2>Riddle: Results</h2>
      </div>
      {message && <p>{message}</p>}
      {results ? (
        <div className="results-details">
          <div><strong>Batch ID:</strong> {results.batchId}</div>
          {/* Removed File Name display */}
          <div><strong>Status:</strong> {results.status}</div>
          <div><strong>Upload Date:</strong> {formatDateTime(results.uploadDate).date}</div>
          <div><strong>Upload Time:</strong> {formatDateTime(results.uploadDate).time}</div>
          <div><strong>Processed Date:</strong> {results.processedDate ? formatDateTime(results.processedDate).date : 'N/A'}</div>
          <div><strong>Processed Time:</strong> {results.processedDate ? formatDateTime(results.processedDate).time : 'N/A'}</div>
          <div className="separator"></div>
          <div><strong>Task Type:</strong> {results.taskType}</div>
          {renderResultsContent(results.results)}
          {renderTaskMetadata(results.taskMetadata)}
          {renderModelLinks(results)} {/* Conditionally render model links */}
        </div>
      ) : (
        !message && <p>Loading...</p>
      )}
    </div>
  );
}

export default withAuthenticator(ResultsPage);